import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import styled from 'styled-components';

import { Typography } from '@mui/material';

import ControlledTextField from '../common/ControlledInputs/ControlledTextField';
import ControlledPasswordField from '../common/ControlledInputs/ControlledPasswordField';
import Alert from '../common/Alert';
import useSpotifyAuth from '../../hooks/useSpotifyAuth';

import { EMAIL_PATTERN, SMALL_SCREEN_SIZE, PASSWORD_RESET_PATH } from '../common/constants';
import theme from '../common/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  min-width: 400px;

  @media (max-width: ${SMALL_SCREEN_SIZE}) {
    min-width: 300px;
  }
`;

const StyledLink = styled(Link)`
  color: ${theme.palette.juxy.secondary};
  margin-left: 5px;
  text-decoration: underline;
`;

function LoginForm() {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  useSpotifyAuth();

  const renderAlert = (message) => {
    setAlertMessage(message);
    setShowAlert(true);
  };

  return (
    <Container>
      <Typography variant="h5">Login</Typography>
      <FormProvider handleSubmit={handleSubmit}>
        <Form onSubmit={handleSubmit}>
          <ControlledTextField
            name="email"
            label="Email"
            rules={{ required: true, pattern: EMAIL_PATTERN }}
            errors={errors}
            control={control}
          />
          <ControlledPasswordField
            name="password"
            label="Password"
            rules={{ required: true }}
            errors={errors}
            control={control}
          />
          <StyledLink to={PASSWORD_RESET_PATH} style={{ margin: '-15px 0 0 0' }}>
            Forgot your password?
          </StyledLink>
          <Outlet
            context={{
              renderAlert,
            }}
          />
        </Form>
      </FormProvider>
      <Alert
        isDisplayed={showAlert}
        variant="filled"
        onClick={() => setShowAlert(false)}
        message={alertMessage}
      />
    </Container>
  );
}

export default LoginForm;
