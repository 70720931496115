import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Divider, Typography } from '@mui/material';

import { getUserRole, isUserLoggedIn, isUserVerified } from '../store/slices/userReducer';

import {
  GUEST_DASHBOARD_PATH,
  HOST_DASHBOARD_PATH,
  REGISTER_GUEST_PATH,
  REGISTER_HOST_PATH,
  USER_TYPE_GUEST,
} from './common/constants';
import UserTypeButtons from './common/UserTypeButtons';
import AlreadyHaveAccount from './Registration/AlreadyHaveAccount';

import JuxyLogo from '../images/JuxyLogo.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
`;

const Logo = styled.img`
  margin: 20px 0;
  border-radius: 10px;
`;

const Headline = styled.h1`
  font-size: ${(props) => props.fontSize || '22px'};
  text-align: center;
  margin: 0.25rem 0;
`;

const dividerStyle = {
  minWidth: 350,
  margin: '20px auto',
  borderColor: 'rgba(255, 255, 255, 0.3)',
};

const TextContainer = styled.div`
  margin-bottom: 20px;
`;

const text = [
  'The First Crowd Focused Social DJ',
  'Allowing You To Take Control',
  "Over Your Life's Soundtrack",
];

function Home() {
  const isLoggedIn = useSelector(isUserLoggedIn);
  const verified = useSelector(isUserVerified);
  const role = useSelector(getUserRole);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      const isUserTypeGuest = role === USER_TYPE_GUEST;
      let path;
      if (verified) {
        path = isUserTypeGuest ? GUEST_DASHBOARD_PATH : HOST_DASHBOARD_PATH;
      } else {
        path = isUserTypeGuest ? REGISTER_GUEST_PATH : REGISTER_HOST_PATH;
      }
      if (path) {
        navigate(path);
      }
    }
  }, [isLoggedIn])

  return (
    <Container>
      <Logo alt="Juxy" width={300} src={JuxyLogo} />
      <Headline>Make Your Taste Matter</Headline>
      <Divider flexItem sx={dividerStyle} />
      <TextContainer>
        {text.map((line) => (
          <Typography key={line[0]} align="center" sx={{ font: 'inherit', fontSize: '22px' }}>
            {line}
          </Typography>
        ))}
      </TextContainer>
      <UserTypeButtons />
      <AlreadyHaveAccount margin="90px 0 0 0" />
    </Container>
  );
}

export default Home;
