/* eslint max-len: "off", no-param-reassign: "off"  */
import { errorTypeTexts } from './constants';

export const getControlledErrorText = (value, errors) => {
  const errorType = errors[value]?.type;
  return errors[value] && errorTypeTexts[value][errorType];
};

export const getHash = () => (
  /*
    looking on the current page url from the hash sign (including)
    and splits it into its query response parts, returning them as an object.
  */
  !window ? ''
    : window.location.hash
      .substring(1)
      .split('&')
      .reduce((initial, item) => {
        if (item) {
          const parts = item.split('=');
          initial[parts[0]] = decodeURIComponent(parts[1]);
        }
        return initial;
      }, {})
);

export const getSearch = () => (
  // separates the query string from the URL, returning the answers as an object.
  !window ? ''
    : window.location.search
      .substring(1)
      .split('&')
      .reduce((initial, item) => {
        if (item) {
          const parts = item.split('=');
          initial[parts[0]] = decodeURIComponent(parts[1]);
        }
        return initial;
      }, {})
);

// Normalize song time to % for progress bar
export const normalize = (value, songDuration) => (value * 100) / songDuration;

export const numberToStringMMSS = (songDuration) => {
  const secNum = parseInt(songDuration, 10); // don't forget the second param
  const hours = Math.floor(secNum / 3600);
  let minutes = Math.floor((secNum - (hours * 3600)) / 60);
  let seconds = secNum - (hours * 3600) - (minutes * 60);

  if (minutes < 10) { minutes = `${minutes}`; }
  if (seconds < 10) { seconds = `0${seconds}`; }
  return `${minutes}:${seconds}`;
};

export const isEmptyObject = (obj) => !obj || Object.keys(obj).length === 0;

export const openSongOnSpotify = (songId) => {
  const spotifyLink = `https://open.spotify.com/track/${songId}?si=`; // the si= query invokes spotify's open in app popup.
  songId && window.open(spotifyLink, '_blank');
};

export const isInArrayById = (arr, id) => arr?.some((obj) => obj.id === id);
