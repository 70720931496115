import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import IconButton from '@mui/material/IconButton';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import BlacklistButton from './BlacklistButton';

import useGAEventTracker from '../../../../hooks/useGAEventTracker';
import {
  VERY_SMALL_SCREEN_SIZE,
  GA_HOST_ACTIVITY,
  GA_LIKED_SONG_ACTIVITY,
  GA_REMOVE_SONG_ACTIVITY,
} from '../../../common/constants';
import { setLikeSong, removeSong } from '../../../../services/api';
import theme from '../../../common/theme';

const Container = styled.div`
    display: flex;
    align-items: center;
`;

const SongActions = styled.div`
    display: flex;
    gap: 14px;

  @media (max-width: ${VERY_SMALL_SCREEN_SIZE}) {
    gap: 8px;
  }
`;

const StyledIconButton = styled(IconButton)({
  color: (props) => props.$isLiked ? theme.palette.juxy.main : 'white',
  '&:hover': {
    color: (props) => props.$isLiked ? 'white' : theme.palette.juxy.main,
  },
  '&.Mui-disabled': {
    color: '#C7CC7C30',
  },
});

function SongControls({
  songId = '',
  isPlaying = false,
  isLiked = false,
  onError = () => {},
  onLikeSongClicked = () => {},
}) {
  const gaEventTracker = useGAEventTracker(GA_HOST_ACTIVITY);

  const handleLikeSongClicked = () => {
    gaEventTracker(GA_LIKED_SONG_ACTIVITY);
    if (songId) {
      setLikeSong(songId)
      .then(({ success }) => {
        if (!!success) {
          onLikeSongClicked();
        }
      })
      .catch((err) => onError(err.message));
    }
  };

  const handleRemoveSongClicked = () => {
    gaEventTracker(GA_REMOVE_SONG_ACTIVITY);
    songId && removeSong(songId).catch((err) => console.error(err)); // TODO: handle error
  };

  return (
    <Container>
      <SongActions>
        <StyledIconButton
          aria-label="like song"
          size="small"
          $isLiked={isLiked}
          onClick={handleLikeSongClicked}
        >
          <FavoriteBorderOutlinedIcon fontSize="small" />
        </StyledIconButton>
        <BlacklistButton songId={songId} />
        <StyledIconButton
          aria-label="remove song"
          size="small"
          onClick={handleRemoveSongClicked}
          // disallow remove playing song TODO: remove when BE logic fixed
          disabled={isPlaying}
        >
          <RemoveCircleOutlineIcon fontSize="small" />
        </StyledIconButton>
      </SongActions>
    </Container>
  );
}

SongControls.propTypes = {
  songId: PropTypes.string.isRequired,
  isPlaying: PropTypes.bool,
  isLiked: PropTypes.bool,
  onLikeSongClicked: PropTypes.func,
  onError: PropTypes.func,
};

export default SongControls;
