import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '../IconButton';
import { SpotifyIcon } from '../icons';
import theme from '../theme';

function SpotifyButton({ onClick = () => {}, label = undefined, disabled = false, sx = {} }) {
  return (
    <IconButton
      type="link"
      startIcon={<SpotifyIcon />}
      onClick={onClick}
      label={label}
      disabled={disabled}
      sx={{
        backgroundColor: theme.palette.spotify.main,
        color: 'white',
        borderRadius: '50px',
        height: '40px',
        width: '100%',
        alignSelf: 'center',
        '&:hover': {
          backgroundColor: theme.palette.spotify.hover,
        },
        '&:disabled': {
          opacity: '50%',
          backgroundColor: theme.palette.spotify.main,
        },
        ...sx,
      }}
    />
  );
}

SpotifyButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  sx: PropTypes.shape({}),
};

export default SpotifyButton;
