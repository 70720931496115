import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useMediaQuery } from '@mui/material';
import Card from '@mui/material/Card';
import PeopleIcon from '@mui/icons-material/People';

import SongDetails from '../SongDetails';
import SongControls from '../SongControls';
import Image from '../../../../images/JuxyDiscLogo.png';
import theme from '../../../common/theme';
import { isEmptyObject } from '../../../common/misc';
import { VERY_SMALL_SCREEN_SIZE, MEDIUM_SCREEN_SIZE } from '../../../common/constants';

const StyledCard = styled(Card)({
  '&.MuiCard-root': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'white',
    borderRadius: '0px',
    background: (props) => (props.$isPlaying ? `${theme.palette.juxy.main}25` : 'none'),
    border: `1px solid ${theme.palette.juxy.secondary}`,
    borderWidth: '0 0 1px 0',
    padding: '8px 10px',
    gap: '16px',
  },
});

const SongNumber = styled.span`
  color: ${(props) => (props.$isPlaying ? theme.palette.juxy.main : '#666666')};
  min-width: 16px;

  @media (max-width: ${VERY_SMALL_SCREEN_SIZE}) {
    min-width: 8px;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  @media (max-width: ${VERY_SMALL_SCREEN_SIZE}) {
    gap: 4px;
  }
`;

const SongSource = styled.div`
    display: flex;
    align-items: center;
    max-height: 22px;
    padding: 4px;
    opacity: 75%;
    background-color: #44A9DB;
    border-radius: 4px;
`;

const iconSourceMap = {
  requestSong: <PeopleIcon sx={{ fontSize: '12px', marginLeft: '4px' }} />,
  voting: undefined,
  vote: undefined,
};

function SongCard({ song = {}, listIndex = 1, onLikeSongClicked = () => {} }) {
  const isMobile = useMediaQuery(`(max-width: ${MEDIUM_SCREEN_SIZE})`);
  const { id, name, artist, image = Image, source, isPlaying, isLiked } = song;

  return (
    <StyledCard $isPlaying={isPlaying}>
      <DescriptionContainer>
        <SongNumber $isPlaying={isPlaying}>{listIndex}</SongNumber>
        <SongDetails songName={name} artist={artist} image={image} />
        {!isEmptyObject(source) && !isMobile && (
          <SongSource>
            <span>{source?.value}</span>
            {iconSourceMap[source?.type]}
          </SongSource>
        )}
      </DescriptionContainer>
      <SongControls
        songId={id}
        isPlaying={isPlaying}
        isLiked={isLiked}
        onLikeSongClicked={onLikeSongClicked}
      />
    </StyledCard>
  );
}

SongCard.propTypes = {
  song: PropTypes.shape({}),
  listIndex: PropTypes.number,
  onLikeSongClicked: PropTypes.func,
};

export default SongCard;
