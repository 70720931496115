import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import styled from 'styled-components';

import AppBar from '@mui/material/AppBar';
import Snackbar from '@mui/material/Snackbar';

import SongDetails from './SongDetails';
import MusicControls from './MusicControls';
import VolumeControl from './VolumeControl';
import OptionsControls from './OptionsControls';

import useSocket from '../../hooks/useSocket';
import { getCurrentSong } from '../../services/api';
import { getLikedSongs, toggleLikeSong } from '../../store/slices/userReducer';
import { MEDIUM_SCREEN_SIZE, SMALL_SCREEN_SIZE } from '../common/constants';

const PlaylistContainer = styled(AppBar)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  top: auto;
  bottom: 0;
  padding: 8px 24px;
  background: #131313;
  border-top: 1px solid #fff;
  color: #fff;

  @media (min-width: ${SMALL_SCREEN_SIZE}) {
    opacity: 0.95;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledSnackbar = styled(Snackbar)`
  opacity: 0.95;
`;

const DEFAULT_ERROR_MESSAGE = 'Operation Failed. Do you have your streaming service running?';

function Player() {
  const isDesktop = useMediaQuery(`(min-width: ${SMALL_SCREEN_SIZE})`);
  const isTablet = useMediaQuery(`(min-width: ${MEDIUM_SCREEN_SIZE})`);

  const [currentSong, setCurrentSong] = useState({});
  const [error, setError] = useState({ isActive: false, message: '' });
  const userLikedSongs = useSelector(getLikedSongs);

  const dispatch = useDispatch();

  const handleNewSong = ({ song }) => {
    setCurrentSong(song);
  };

  const handleError = (message = DEFAULT_ERROR_MESSAGE) => {
    setError({ isActive: true, message });
  };

  useSocket(undefined, { new_song: handleNewSong });

  useEffect(() => {
    const getCurrSong = async () => {
      const currSongData = await getCurrentSong();
      handleNewSong({ song: currSongData.song });
    };

    getCurrSong();
  }, []);

  return (
    <PlaylistContainer position="fixed">
      <Container style={{ gap: '24px' }}>
        <SongDetails
          songName={currentSong?.name}
          artist={currentSong?.artist}
          image={currentSong?.image}
        />
        {isTablet && (
          <OptionsControls
            songId={currentSong?.id}
            spotifyId={currentSong?.engineData?.spotify?.id}
            isLiked={userLikedSongs?.includes(currentSong?.id)}
            onError={handleError}
            onLikeSong={() => dispatch(toggleLikeSong(currentSong?.id))}
          />
        )}
      </Container>
      <MusicControls onError={handleError} />
      {isDesktop && <VolumeControl onError={handleError} />}
      <StyledSnackbar
        open={error.isActive}
        autoHideDuration={3000}
        onClose={() => setError({ isActive: false, message: '' })}
        message={error.message}
      />
    </PlaylistContainer>
  );
}

export default Player;
