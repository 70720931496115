import { createAsyncThunk } from '@reduxjs/toolkit';
import { Navigate } from 'react-router-dom';

import { logout, login } from '../slices/userReducer';
import { getProfile } from '../../services/api';
import { USER_TYPE_GUEST, USER_TYPE_HOST, REGISTER_PATH } from '../../components/common/constants';

export const fetchProfile = createAsyncThunk('user/profile', async (_, { dispatch, getState }) => {
  const { user } = getState();
  if (user?.isLoggedIn) {
    return user;
  }

  const profile = await getProfile();
  if (!!profile.result === false) {
    dispatch(logout());
    throw new Error('User not found');
  }

  const dispatchedProfile = {
    isLoggedIn: true,
    verified: profile.verified,
    role: profile.user_type, // Guest or Host
    id: profile.id,
    email: profile.email,
    displayName: profile.display_name,
    gender: profile.gender,
    yob: profile.yob,
    image: profile.image,
    likedSongs: profile.liked_songs.map(song => song.id) || [],
  };

  if (dispatchedProfile.role === USER_TYPE_HOST) {
    dispatchedProfile.venues = profile.venues;
  } else {
    dispatchedProfile.role = USER_TYPE_GUEST; // TODO: remove after BE fixes Guest role from User to Guest.
    dispatchedProfile.userType = profile.type; // manual or streaming service
  }

  dispatch(login(dispatchedProfile));
  if (dispatchedProfile.isLoggedIn && !dispatchedProfile.verified) {
    Navigate(`${REGISTER_PATH}/${dispatchedProfile.role}`);
    throw new Error('Unverified user profile');
  }
  return dispatchedProfile;
});
