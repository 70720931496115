import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import useAuth from '../../hooks/useAuthFunctions';
import { isUserLoggedIn } from '../../store/slices/userReducer';
import {
  NAME,
  HOME_PATH,
  ABOUT_US_PATH,
  EDIT_PROFILE_PATH,
  MUSIC_LIBRARY_PATH,
  GUEST_LOGIN_PATH,
  CONTACT_US_PATH,
} from './constants';
import drawerBackground from '../../images/drawer-background.jpeg';
import JuxyLogoSmall from '../../images/JuxyLogoSmall.png';

const StyledDrawer = styled(Drawer)({
  '& .MuiDrawer-paper': {
    minWidth: '200px',
    width: '40%',
    padding: '20px',
    backgroundImage: `url(${drawerBackground})`,
  },
});

const Logo = styled.img`
  width: 126px;
  height: 60px;
  margin: 16px 0;
  border-radius: 10px;
  cursor: pointer;
`;

const Title = styled(Link)({
  color: 'white',
  fontSize: '70px',
  fontWeight: 700,
  margin: '40px 0 80px 0',
});

const StyledLink = styled(Link)({
  color: 'white',
  fontSize: '24px',
  fontWeight: 700,
  margin: '10px 0',
  textDecoration: 'none',
});

function Header() {
  const navigate = useNavigate();
  const isLoggedIn = useSelector(isUserLoggedIn);
  const { logout } = useAuth();

  const [openDrawer, setOpenDrawer] = useState(false);

  const isCurrentHomePath = window.location.pathname === HOME_PATH;
  const isLoggedOutHomePage = isCurrentHomePath && !isLoggedIn;

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleLogout = () => {
    logout(); // removes user from redux store and logs user out from backend.
    toggleDrawer();
  };

  const handleLogoOnClick = () => {
    navigate(HOME_PATH);
  };

  const renderDrawerMenu = () => (
    <StyledDrawer anchor="left" open={openDrawer} onClose={toggleDrawer}>
      <Title to="" onClick={toggleDrawer}>
        {NAME}
      </Title>
      {isLoggedIn && (
        <StyledLink className="item" to={MUSIC_LIBRARY_PATH} onClick={toggleDrawer}>
          My Music
        </StyledLink>
      )}
      <StyledLink
        className="item"
        to={ABOUT_US_PATH}
        onClick={toggleDrawer}
      >{`About ${NAME}`}</StyledLink>
      <StyledLink className="item" to={CONTACT_US_PATH} onClick={toggleDrawer}>
        Contact Us
      </StyledLink>
      {isLoggedIn && (
        <StyledLink className="item" to={EDIT_PROFILE_PATH} onClick={toggleDrawer}>
          Edit Profile
        </StyledLink>
      )}
      {isLoggedIn && (
        <StyledLink className="item" to={GUEST_LOGIN_PATH} onClick={handleLogout}>
          Log Out
        </StyledLink>
      )}
    </StyledDrawer>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar
          sx={{
            height: '90px',
            justifyContent: isLoggedOutHomePage ? 'end' : 'space-between',
            backgroundColor: '#3A2788',
          }}
        >
          {!isLoggedOutHomePage && <Logo src={JuxyLogoSmall} onClick={handleLogoOnClick} />}
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
          >
            <MenuIcon sx={{ fontSize: '40px' }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      {renderDrawerMenu()}
    </Box>
  );
}

export default Header;
