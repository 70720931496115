import React from 'react';
import Typography from '@mui/material/Typography';

import { NAME } from './constants';

const style = {
  color: '#999999',
  fontSize: '12px',
  position: 'absolute',
  bottom: '25px',
  right: 0,
  left: 0,
};

function Footer() {
  return (
    <Typography align="center" sx={style}>{`©${NAME} 0.1a`}</Typography>
  );
}

export default Footer;
